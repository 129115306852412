
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { getContractAddresses } from "@/utils/networks";

import MintView from "@/components/MintView.vue";
import NetworkError from "@/components/NetworkError.vue";

import { loadedAssets } from "../resources/crypto";

const contentsToken = {
  wabi: require("../abis/MaterialToken.json"), // wrapped abi
};

export default defineComponent({
  name: "MaterialsMint",
  components: {
    MintView,
    NetworkError,
  },
  setup() {
    const route = useRoute();
    const network =
      typeof route.query.network == "string" ? route.query.network : "mainnet";
    const addresses = getContractAddresses(network);
    if (!addresses) {
      return {
        error: true,
      };
    }
    addresses.tokenAddress = addresses.materialAddress;
    const options = {
      tokenOffset: network == "goerli" ? 0 : 754,
      svgStyle: 0,
      initTokenPer: 4,
      tokenName: "MaterialToken",
    };
    return {
      error: false,
      addresses,
      contentsToken,
      loadedAssets,
      options,
    };
  },
});
