
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { getContractAddresses } from "@/utils/networks";

import MintView from "@/components/MintView.vue";
import NetworkError from "@/components/NetworkError.vue";

import { loadedAssets } from "../resources/emoji";

const contentsToken = {
  wabi: require("../abis/EmojiFlagToken.json"), // wrapped abi (KamonToken has the idential API)
};

export default defineComponent({
  name: "EmojiMint",
  components: {
    MintView,
    NetworkError,
  },
  setup() {
    const route = useRoute();
    const network =
      typeof route.query.network == "string" ? route.query.network : "mainnet";
    const addresses = getContractAddresses(network);
    if (!addresses) {
      return {
        error: true,
      };
    }
    addresses.tokenAddress = addresses.flagAddress;
    const options = {
      tokenOffset: network == "goerli" ? 0 : 148,
      svgStyle: 8,
      initTokenPer: 0,
      tokenName: "EmojiFlagToken",
    };
    return {
      error: false,
      addresses,
      contentsToken,
      loadedAssets,
      options,
    };
  },
});
