
import { defineComponent, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ethers } from "ethers";
import NetworkGate from "@/components/NetworkGate.vue";
import { identityTransform, Transform, Drawing } from "@/models/point";
import { weiToEther } from "@/utils/currency";

export default defineComponent({
  props: [
    "selection",
    "addresses",
    "tokensPerAsset",
    "tokenAbi",
    "assetStoreRO",
    "priceRange",
    "drawing",
    "isRemix",
  ],
  components: {
    NetworkGate,
  },
  setup(props, context) {
    const route = useRoute();
    const store = useStore();

    const affiliateId =
      typeof route.query.ref == "string" ? parseInt(route.query.ref) || 0 : 0;

    const networkContext = computed(() => {
      if (
        store.state.account &&
        store.state.chainId == props.addresses.chainId
      ) {
        const provider = new ethers.providers.Web3Provider(
          store.state.ethereum
        );
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          props.addresses.tokenAddress,
          props.tokenAbi,
          signer
        );

        return { provider, signer, contract };
      }
      return null;
    });

    const minterName = ref("");
    const validName = computed(() => {
      const length = encoder.encode(minterName.value).length;
      return length <= 32;
    });
    const messageRef = ref<string | null>(null);
    watch(
      () => props.selection,
      () => {
        messageRef.value = null;
      }
    );
    const encoder = new TextEncoder();
    const mint = async () => {
      //console.log("*** mint", selection.value.asset.asset);
      if (!networkContext.value) {
        console.error("Mint: we are not supposed to come here");
        return;
      }
      if (!props.selection) {
        console.error("Mint: no selection");
        return;
      }
      const asset = props.selection.asset;
      try {
        const result = await props.assetStoreRO.functions.getAssetIdWithName(
          asset.group,
          asset.category,
          asset.name
        );
        // Double-check if it's already minted
        if (result[0].toNumber() > 0) {
          messageRef.value = "message.not_available";
          return;
        }
      } catch (e) {
        // this is success
      }
      const transformString = (xf: Transform) => {
        if (
          xf.tx == identityTransform.tx &&
          xf.ty == identityTransform.ty &&
          xf.scale == identityTransform.scale &&
          xf.rotate == identityTransform.rotate
        ) {
          return "";
        }
        const d = Math.round(512 * (xf.scale - 1));
        return (
          `translate(${xf.tx - d} ${xf.ty - d}) ` +
          `scale(${xf.scale}) rotate(${xf.rotate} 512 512)`
        );
      };

      asset.soulbound = await networkContext.value.signer.getAddress();
      //console.log(asset.soulbound);
      try {
        messageRef.value = "message.minting";
        asset.minter = minterName.value;
        // asset.group = ""; // gas saving
        let tx;
        if (props.drawing) {
          const drawing = props.drawing as Drawing;
          const hasRemix = drawing.remix.image;
          const remixes = hasRemix
            ? [
                {
                  tokenId: drawing.remix.tokenId,
                  fill: drawing.remix.color || "",
                  transform: transformString(drawing.remix.transform),
                  stroke: 0,
                },
              ]
            : [];
          const txParams: any = {};
          if (hasRemix || drawing.overlays.length > 0) {
            const mintPrice = await networkContext.value.contract.mintPrice();
            console.log(
              "*** minting",
              remixes.length,
              drawing.overlays.length,
              weiToEther(mintPrice)
            );
            txParams.value = mintPrice;
          } else {
            console.log("*** minting", remixes.length, drawing.overlays.length);
          }

          const overlays = drawing.overlays.map((overlay) => {
            return {
              assetId: overlay.assetId,
              provider: overlay.provider,
              fill: overlay.fill,
              transform: transformString(overlay.transform),
              stroke: 0,
            };
          });
          // console.log("overlays", overlays);
          tx = await networkContext.value.contract.mintWithAsset(
            asset,
            affiliateId,
            drawing.stroke,
            remixes,
            overlays,
            txParams
          );
        } else {
          tx = await networkContext.value.contract.mintWithAsset(
            asset,
            affiliateId
          );
        }
        const result = await tx.wait();
        console.log("mint:gasUsed", result.gasUsed.toNumber());
        messageRef.value = "message.minted";
        context.emit("minted");
      } catch (e: any) {
        console.log("*** mint error", e);
        messageRef.value = e.message;
      }
    };
    return {
      minterName,
      validName,
      mint,
      messageRef,
    };
  },
});
