import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-2 text-xl font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KeyMessage = _resolveComponent("KeyMessage")!
  const _component_AssetsPanel = _resolveComponent("AssetsPanel")!
  const _component_MintPanel = _resolveComponent("MintPanel")!
  const _component_NFTList = _resolveComponent("NFTList")!
  const _component_References = _resolveComponent("References")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createVNode(_component_KeyMessage),
    _createVNode(_component_AssetsPanel, {
      onOnSelect: _cache[0] || (_cache[0] = (asset) => _ctx.onSelect(asset)),
      availableAssets: _ctx.availableAssets,
      loadedAssets: _ctx.loadedAssets
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["availableAssets", "loadedAssets"]),
    _createVNode(_component_MintPanel, {
      selection: _ctx.selection,
      tokenAbi: _ctx.tokenAbi,
      addresses: _ctx.addresses,
      tokensPerAsset: _ctx.tokensPerAsset,
      assetStoreRO: _ctx.assetStoreRO,
      priceRange: _ctx.priceRange
    }, null, 8, ["selection", "tokenAbi", "addresses", "tokensPerAsset", "assetStoreRO", "priceRange"]),
    _createVNode(_component_NFTList, {
      tokens: _ctx.tokens,
      OpenSeaPath: _ctx.OpenSeaPath
    }, null, 8, ["tokens", "OpenSeaPath"]),
    _createVNode(_component_References, {
      EtherscanStore: _ctx.EtherscanStore,
      EtherscanToken: _ctx.EtherscanToken,
      TokenName: _ctx.tokenName
    }, null, 8, ["EtherscanStore", "EtherscanToken", "TokenName"])
  ]))
}