import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "mb-2" }
const _hoisted_5 = ["onClick", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.availableAssets == null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("message.loading")), 1)
        ]))
      : (_ctx.availableAssets.length == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$tc("assetPanel.thanks", { totalCount: _ctx.loadedAssets.length })), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("assetPanel.mint")), 1),
            _renderSlot(_ctx.$slots, "default"),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableAssets, (asset) => {
              return (_openBlock(), _createElementBlock("span", {
                key: asset.name
              }, [
                _createElementVNode("img", {
                  onClick: ($event: any) => (_ctx.$emit('onSelect', asset)),
                  src: asset.image,
                  class: "inline-block w-16 cursor-pointer rounded-xl"
                }, null, 8, _hoisted_5)
              ]))
            }), 128))
          ]))
  ]))
}