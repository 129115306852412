import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "mx-auto max-w-xl p-2 text-left"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NetworkError = _resolveComponent("NetworkError")!
  const _component_KamonMessage = _resolveComponent("KamonMessage")!
  const _component_mint_view = _resolveComponent("mint-view")!

  return (_ctx.error)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_NetworkError)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_mint_view, {
          addresses: _ctx.addresses,
          title: "Kamon Symbols by Hakko Daiodo",
          priceRange: { low: 0.04, high: 0.23 },
          contentsToken: _ctx.contentsToken,
          loadedAssets: _ctx.loadedAssets,
          options: _ctx.options
        }, {
          default: _withCtx(() => [
            _createVNode(_component_KamonMessage)
          ]),
          _: 1
        }, 8, ["addresses", "priceRange", "contentsToken", "loadedAssets", "options"])
      ]))
}